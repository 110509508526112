@import "../../assets/styles/variables.module";

.post_detail_page {
  padding: 80px 0;
  &__background {
    width: 100%;
    height: 28.375rem; // 454px
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4.75rem;
    &_get_started {
      margin: 15px auto 0;
      background: $primaryYellow;
      color: $primaryBlack;
      box-sizing: border-box;
      border-radius: 100px;
      width: 13rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        opacity: 0.7;
      }
    }
    &_more {
      background: $primaryBlack;
      color: #FFF;
      border: 2px solid $primaryYellow;
      box-sizing: border-box;
      border-radius: 100px;
      width: 13rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: $primaryYellow;
        color: $primaryBlack;
      }
    }
  }
  &__content {
    background: #FFF;
    width: 100%;
    padding: 3.125rem 8.875rem; // 50px;
    max-width: 56.25rem; //900px;
    margin: -17.3125rem auto 0;
    &_type {
      text-transform: uppercase;
      color: $primaryGrey;
    }
    &_title {margin: 1.75rem 0;}
    &_author {
      text-align: center;
      color: $primaryGrey;
    }
    &_header {
      text-align: center;
      .f-22-30 {color: $primaryGrey;}
    }
    &_inner {
      position: relative;
      margin-left: -5.1875rem;
      margin-top: 3.125rem;
      display: flex;
    }
    &_share {
      margin-right: 2.6875rem;
      margin-top: 20px;
      & .share_buttons {
        position: sticky;
        top: calc(80px + 20px);
      }
    }
    &_text {
      flex-grow: 1;
      overflow: hidden;
      font-size: 1.2rem;
      & h2 {
        font-size: 1.775rem!important;
        line-height: 1.875rem!important;
        font-weight: 500!important;
      }
      & a {
        text-decoration: underline!important;
      }
      & img {
        max-width: 100%!important;
        display: inline-block;
        font-family: Arial, sans-serif;
        font-weight: 300;
        line-height: 2;
        text-align: center;
        min-width: 300px;
        min-height: 50px;
        position: relative;
        border: none;
        &::before {
          content: '';
          width: 100%;
          height: calc(100% + 10px);
          background-color: #ccc;
          border-radius: 7px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        &::after {
          content: "\2639" " " attr(alt);
          font-size: 18px;
          color: rgb(100, 100, 100);
          display: block;
          position: absolute;
          z-index: 2;
          top: 5px;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__error {
    text-align: center;
    color: $primaryBlack;
    display: flex;
    height: 50vh;
    align-items: center;
    justify-content: center;
    &_content {
      padding: 4rem 2rem;
      background: #FFF;
      border-radius: 10px;
      width: 100%;
      max-width: 50rem;
      text-align: center;
      font-size: 1rem;
      line-height: 1.7rem;
    }
  }
}


@media screen and (max-width: 992px) {
  .post_detail_page {
    &__content {
      padding: 3.125rem 1.5rem;
      &_inner {
        position: relative;
        margin-left: unset;
        margin-top: 10px;
        display: revert;
      }
      &_text {
        margin-top: 3.125rem;
      }
      &_share {
        margin-right: 0;
        & .share_buttons {
          position: relative;
          top: unset;
        }
        top: unset;
        right: unset;
        display: flex;
        align-items: center;
        justify-content: center;

        & .share_buttons {
          position: unset;
          flex-direction: row;
          & button:not(:first-child) {
            margin-top: unset;
            margin-left: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .post_detail_page {
    padding: 0;
    &__background {
      display: none;
    }
    &__content {
      margin: 0 auto;
    }
    & .container {
      padding: 0;
    }
  }
}


.unreset {
  $value: revert;
  div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: $value;
    padding: $value;
    border: $value;
    font-size: $value;
    font: $value;
    vertical-align: $value;
  }
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {display: $value;}
  ol, ul {list-style: $value;}
  blockquote, q {quotes: $value;}
  blockquote:before, blockquote:after,
  q:before, q:after {content: $value;}
  table {border-collapse: $value;border-spacing: $value;}
  input, select, textarea, button, optgroup{font-family: $value; font-size: $value; line-height: $value; margin: $value;}
  button,input[type="button"], input[type="submit"], input[type="reset"]{cursor: $value; -webkit-appearance: $value; }
  input:focus, button:focus, select:focus, textarea:focus {outline:$value}
  textarea{overflow: $value;}
  *{box-sizing: $value;}
  hr{box-sizing: $value;height: $value;overflow: $value;}
  img, iframe{max-width: $value; height: $value;}
  a {background-color: $value; -webkit-text-decoration-skip: $value;}
  img {border-style: $value;}
  .clearfix::after{clear: $value; content: $value; display: $value;}
  button {border: $value;background: $value;padding: $value;}
}