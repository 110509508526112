@import "../../../assets/styles/variables.module";
@import "../../../assets/styles/mixins.module";

.horizontal_post_card {
  display: block;
  overflow: hidden;
  transform: scale(1);
  transition: all .3s ease-out;
  &:hover {
    transform: scale(1.01);
    opacity: 0.9;
    transition: all .3s ease-out;
    .horizontal_post_card__content_title {
      color: $primaryYellow;
    }
  }
  &__inner {
    display: grid;
    grid-template-columns:  minmax(0, 55%) minmax(0, 1fr);
    grid-column-gap: 1.875rem;
    justify-content: space-between;
  }
  &__image {
    flex-grow: 1;
    min-height: 390px;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__content {
    color: #FFF;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-grow: 1;
    &_type {
      text-transform: uppercase;
      color: $primaryGrey;
    }
    &_title {
      margin-top: 0.625rem;
      font-weight: 400;
    }
    &_desc {
      margin-top: 0.9375rem;
      //@include clampText(5);
    }
    &_bottom {
      opacity: 0.5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 4.6875rem; // 75px
    }
  }
}