.socialButton_instagram {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 100%;
    border: 1px solid black;

    &:hover {
        background: black;

        svg {
            path {
                fill: white;
            }
        }
    }
}