@import "../../assets/styles/variables.module";

.share_buttons {
  display: flex;
  flex-direction: column;
  & button {
    display: inline-block;
    &:not(:first-child) {
      margin-top: 0.9rem;
    }
  }
  & span {
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #FFD11C;
    border-radius: 50%;
    &:hover {
      opacity: 0.8;
      background: $primaryYellow;
      & svg path {
        fill: #FFF;
      }
    }
  }
}