.mobHeader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    nav {
        display: flex;
        align-items: center;
        width: 100%;
        height: 80px;

        .mobLogo {
            margin: 0 auto;
            padding-left: 25px;
            & img {
                width: 93px;
                height: 19px;
            }
        }

        .mobDots-button {
            width: 25px;
            height: 100%;
            outline: none;
            background: transparent;
            border: none;
            cursor: pointer;

            .mobDots {
                display: flex;
                justify-content: space-between;
                width: 100%;

                img {
                    width: 5px;
                    height: 5px;
                }
            }
        }
    }
}

.openMobMenuPopup {
    position: fixed !important;
    z-index: 999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #FFD11C !important;
    overflow: hidden;
    animation: menuAppearance 0.4s ease-in;

    @keyframes menuAppearance {
        0% {
            height: 0;
            background-color: #000000;
        }

        100% {
            height: 100%;
            background-color: #FFD11C;
        }
    }

    .openMobMenu {
        height: 100%;
        overflow: auto;
        .header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 80px;
            position: relative;

            .headerMenu {
                width: 67%;
                display: flex;
                margin-left: auto;
                padding: 0;
                    
                li {
                    list-style: none;
                    opacity: 0.5;

                    &:first-child {
                        margin-right: 15px;
                    }
                }

                .active {
                    opacity: 1;
                }

                @media screen and (max-width: 375px) {
                    width: 66%;
                }
            }

            .mobDots-button {
                width: 25px;
                height: 100%;
                outline: none;
                background: transparent;
                border: none;
                opacity: 1!important;
                cursor: pointer;

                .mobDots {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    img {
                        width: 5px;
                        height: 5px;
                    }
                }
            }
        }

        .openMobMenuList {
            margin-top: 15%;
            padding: 0;
            text-align: left;
            
            li {
                list-style: none;

                .h1 {
                    font-size: 50px;
                    line-height: 60px;
                }
            }
        }

        .buttonsBlock {
            margin-top: 25%;
            display: none;
            justify-content: space-between;
            align-items: center;
            &.open {
                display: flex;
            }
        }

        .footer {
            margin-top: 15%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .socialButtonsGroup {
                display: flex;

                .socialButton_facebook {
                    margin-right: 15px;
                }
    
                .socialButton_linkedIn {
                    margin-right: 15px;
                }
    
                .socialButton_instagram {
                    margin-right: 0;
                }
            }

            @media screen and (max-width: 360px) {
                .socialButtonsGroup {
    
                    .socialButton_facebook {
                        margin-right: 7px;
                    }
        
                    .socialButton_linkedIn {
                        margin-right: 7px;
                    }
                }
            }
        }
    }
}

.pseudo_header {
    height: 80px;
}