/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;}
html, body{width:100%; height:100%;}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {display: block;}
ol, ul {list-style: none;}
blockquote, q {quotes: none;}
blockquote:before, blockquote:after,
q:before, q:after {content: '';content: none;}
table {border-collapse: collapse;border-spacing: 0;}
input, select, textarea, button, optgroup{font-family: inherit; font-size: 100%; line-height: 1.15; margin: 0;}
button,input[type="button"], input[type="submit"], input[type="reset"]{cursor: pointer; -webkit-appearance: button; }
input:focus, button:focus, select:focus, textarea:focus {outline:none}
textarea{overflow: auto;}
*{box-sizing: border-box;}
hr{box-sizing: content-box;height: 0;overflow: visible;}
img, iframe{max-width: 100%; height: auto;}
a {background-color: transparent; -webkit-text-decoration-skip: objects;}
img {border-style: none;}
.clearfix::after{clear: both; content: "";	display: table;}
button {border: none;background: none;padding: 0;}