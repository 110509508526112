.f-400 {font-weight: 400;}
.f-500 {font-weight: 500;}
.f-700 {font-weight: 700;}
.f-900 {font-weight: 900;}

.f-16 {
  font-size: 1rem;
  line-height: 1rem;
}

.f-15-17 {
  font-size: 0.9375rem; // 15px;
  //line-height: 1.0625rem; // 17px;
  line-height: 1.4625rem; // 17px;
}

.f-15-22 {
  font-size: 0.9375rem; // 15px;
  line-height: 1.375rem; // 22px;
}

.f-20-24 {
  font-size: 1.25rem; // 20px;
  line-height: 1.5rem; // 24px;
}

.f-20-30 {
  font-size: 1.25rem; // 20px;
  line-height: 1.875rem; // 30px;
}

.f-22-30 {
  font-size: 1.375rem; // 22px;
  line-height: 1.875rem; // 30px;
}

.f-30-40 {
  font-size: 1.875rem; // 30px;
  line-height: 2.5rem; // 40px;
}

.f-50-60 {
  font-size: 3.125rem; // 50px;
  line-height: 3.75rem; // 60px;
}

.f-40-50 {
  font-size: 2.5rem; // 40px;
  line-height: 3.125rem; // 50px;
}

.f-64-50 {
  font-size: 4rem; // 64px;
  line-height: 3.2rem; // 51;
}

.f-72-80 {
  font-size: 4.5rem; // 72px;
  line-height: 5rem; // 80px;
}