:root {
  --primary-yellow: #FFD11C;
}

.bodyOverflowHidden {
  overflow: hidden!important;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

nav {
  background: transparent;
}

.wrapper {
  padding-left: 80px;
  padding-right: 80px;

  @media screen and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.text-white {
  color: #ffffff;
}

.text-black {
  color: #000000;
}

.text-yellow {
  color: #FFD11C;
}

.text-success {
  color: #22bb33;
}

.text-danger {
  color: #bb2124;
}

.background-white {
  background: #ffffff;
}

.background-black {
  background: #000000;
}

.background-yellow {
  background: #FFD11C;
}

.nonColor {
  color: transparent;
}

.h0 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 113px;
  line-height: 120px;
  text-transform: uppercase;

  @media screen and (max-width: 1024px) {
    font-size: 54px;
    line-height: 60px;
  }

  @media screen and (max-width: 375px) {
    font-size: 48px;
  }

  @media screen and (max-width: 360px) {
    font-size: 46px;
  }
}

.h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 4.5em;
  line-height: 1.1em;

  @media screen and (min-width: 1680px) {
    font-size: 4em;
  }
}

.h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 3.1em;
  line-height: 1.3em;

  @media screen and (max-width: 1024px) {
    font-size: 30px;
    line-height: 40px;
  }
}

.h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 1.86em;
  line-height: 1.3em;

  @media screen and (max-width: 1024px) {
    font-size: 22px;
    line-height: 30px;
  }
}

.h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 1.3em;
  line-height: 1.3em;

  @media screen and (max-width: 1024px) {
    font-size: 22px;
    line-height: 30px;
  }
}

.h5 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
}

.header-text {
  font-family: 'Poppins';
  font-size: 1em;
  font-weight: normal;
  line-height: 1.45em;

  @media screen and (max-width: 1024px) {
    font-size: 15px;
    line-height: 17px;
  }
}

.body-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 0.9em;
  line-height: 1.4em;

  @media screen and (max-width: 1024px) {
    font-size: 15px;
    line-height: 17px;
  }

  @media screen and (max-width: 375px) {
    font-size: 13px;
  }
}

.header-yellow-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  background: #FFD11C;
  border: none;
  border-radius: 90px;
  color: #000000;
  cursor: pointer;

  &:hover {
    background: transparent;
    border: 1px solid #FFD11C;
    color: #ffffff;
  }
}

.header-black-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  background: #000000;
  border: none;
  border-radius: 90px;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background: transparent;
    border: 1px solid #000000;
    color: #000000;
    width: 118px;
  }
}

.mob-header-empty-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: 40px;
  background: transparent;
  border: 1px solid #000000;
  border-radius: 90px;
  color: #000000;
  cursor: pointer;

  @media screen and (max-width: 360px) {
    width: 140px;
  }
}

.mob-header-black-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: 40px;
  background: #000000;
  border: 1px solid #000000;
  border-radius: 90px;
  color: #ffffff;
  cursor: pointer;

  @media screen and (max-width: 360px) {
    width: 140px;
  }
}

.yellow-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 40px;
  background: #FFD11C;
  border: none;
  border-radius: 90px;
  padding: 9px 21px;
  color: #000000;
  cursor: pointer;

  &:hover {
    background: transparent;
    border: 1px solid #FFD11C;
    color: #ffffff;
    padding: 7px 19px;
  }
}

.black-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 40px;
  background: #000000;
  border: none;
  border-radius: 90px;
  padding: 9px 21px;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background: transparent;
    border: 1px solid #000000;
    color: #000000;
    padding: 7px 19px;
  }
}

.empty-button_yellow-outline {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 40px;
  background: transparent;
  border: 1px solid #FFD11C;
  border-radius: 90px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: #FFD11C;
    border: 1px solid #FFD11C;
    color: #000000;
  }
}

.empty-button_black-outline {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 40px;
  background: transparent;
  border: 1px solid #000000;
  color: #000000;
  border-radius: 90px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: #000000;
    border: 1px solid #000000;
    color: #ffffff;
  }
}

.nowrap {
  white-space: nowrap;
}

.dots-button {
  background: transparent;
  border: none;
  margin-left: 20px;
  cursor: pointer;
}

.contacts_social_btn {
  background: transparent;
  border: 2px solid #191919;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  p {
    color: #ffffff;
  }

  &:hover {
    background: #FFD11C;
    border: 2px solid #FFD11C;

    p {
      color: #000000;
    }
  }
}

body.loading {
  overflow: hidden;
}

body.loading #preloader,
body.loaded #preloader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 14;
  background: var(--primary-yellow);
  pointer-events: none;
}

body.loaded #header {
  z-index: 13;
}

body.loading #preloader {
  transform: scale(1);
}

body.loaded #preloader {
  width: 100vh;
  height: 100vh;
  margin: auto;
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.8s linear;

  @media screen and (max-width: 1024px) {
    width: 100vw;
    height: 100vw;
  }
}

.scramble {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "scramble" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.scramble-logo:before {
  content: "" !important;
}

// Cookie-script styles
#cookiescript_injected {
  background-color: #FFD11C !important;
  padding: 40px 160px !important;
  z-index: 9999999999 !important;

  @media screen and (max-width: 1024px) {
    padding: 40px 80px !important;
  }

  @media screen and (max-width: 425px) {
    padding: 20px !important;
  }

  #cookiescript_leftpart {
    margin-bottom: 50px;

    #cookiescript_description {
      font-family: 'Poppins' !important;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;

      #cookiescript_readmore {
        text-decoration: underline;
      }
    }

    #cookiescript_manage_wrap {
      .cookiescript_gear {
        fill: #000000;
      }
    }
  }

  #cookiescript_toppart {
    flex-direction: column !important;
    position: relative;

    #cookiescript_leftpart {
      max-width: 100%;

      #cookiescript_manage_wrap {
        margin: 20px 0 !important;
      }
    }

    #cookiescript_rightpart {
      margin-left: auto;
      position: absolute;
      right: 0;
      top: 65%;
      min-width: 0 !important;

      @media screen and (max-width: 1024px) {
        top: 62%;
      }

      @media screen and (max-width: 425px) {
        top: 65%;
        width: min-content;
      }

      #cookiescript_buttons {
        width: auto !important;
        flex-direction: row !important;

        #cookiescript_save {
          width: 120px;
          height: 40px;
          background: #000000;
          border: none;
          border-radius: 90px;
          color: #ffffff;
          cursor: pointer;
          font-family: 'Poppins' !important;
          font-style: normal;
          font-weight: normal;
          padding-top: 4px;
        }

        #cookiescript_accept {
          width: 120px;
          height: 40px;
          background: #000000;
          border: 1px solid #000000;
          color: #ffffff;
          border-radius: 90px;
          cursor: pointer;
          text-decoration: none;
          font-family: 'Poppins' !important;
          font-style: normal;
          font-weight: normal;
          padding-top: 4px;
        }

        #cookiescript_reject {
          width: 120px;
          height: 38px;
          background: transparent;
          border: 1px solid #000000;
          color: #000000;
          border-radius: 90px;
          cursor: pointer;
          text-decoration: none;
          font-family: 'Poppins' !important;
          font-style: normal;
          font-weight: normal;
          padding-top: 5px;
        }
      }
    }
  }

  #cookiescript_bottompart {

    #cookiescript_cookietablewrap {

      #cookiescript_maintabs {
        background-color: #000000 !important;
        border-bottom: 1px solid #313131;

        #cookiescript_declaration {
          border-right: 1px solid #313131;
        }

        #cookiescript_aboutcookies {
          border-left: 1px solid #313131;
        }

        .cookiescript_active {
          background-color: #000000 !important;
          color: #FFD11C !important;
          font-weight: 700;
        }
      }

      #cookiescript_tabscontent {

        #cookiescript_declarationwrap {
          background-color: #000000 !important;
          color: #ffffff !important;

          #cookiescript_categories {
            border-bottom: none;

            div {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 120px;
              height: 25px;
              background: transparent;
              border: 1px solid #FFD11C;
              border-radius: 90px;
              cursor: pointer;
              text-decoration: none;
              color: #ffffff !important;
              font-family: 'Poppins' !important;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 16px;

              &.cookiescript_active {
                background: #FFD11C;
                color: #000000 !important;
              }
            }
          }

          #cookiescript_reportwrap {
            font-family: 'Poppins' !important;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 15px;

            div {
              color: #ffffff !important;
              font-size: 13px !important;
              line-height: 20px !important;
            }

            table {
              thead {
                tr {
                  th {
                    color: #ffffff !important;
                    background: #000000;
                    border-bottom: 1px solid #313131;
                    font-size: 12px !important;
                    line-height: 14px !important;
                  }
                }
              }
              tbody {
                tr {
                  td {
                    color: #ffffff !important;
                    border-bottom: 1px solid #313131;
                    font-size: 12px !important;
                    line-height: 14px !important;
                  }
                }
              }
            }
          }
        }

        #cookiescript_aboutwrap {
          background-color: #000000 !important;
          color: #ffffff !important;
        }
      }
    }
  }

  span {
    color: #000000 !important;
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: normal;
    font-size: 0.95em;
    line-height: 1.4em;

    @media screen and (max-width: 1024px) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  a {
    color: #000000 !important;
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: normal;
    font-size: 0.95em;
    line-height: 1.4em;

    @media screen and (max-width: 1024px) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  #cookiescript_reportdate {
    color: #000000 !important;
    font-size: 5px;
  }

  #cookiescript_copyright {
    font-size: 7px;

    @media screen and (max-width: 1024px) {
      a {
        font-size: 7px !important;
      }
    }
  }
}

header, #header, #mobHeader {
  background: #00000070;
}