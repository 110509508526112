.mobFooter {
    margin-top: 100px;

    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .mainPart {
            display: flex;
            justify-content: space-between;

            .col1 {
                width: 50%;
                display: flex;
                flex-direction: column;

                .mainPartRow1 {
                    width: 80px;
                    height: auto;
                    margin-bottom: 20px;
                }

                .mainPartRow2 {
                    margin-bottom: 20px;

                    a {
                        text-decoration: none;
                    }
                }

                .mainPartRow3 {
                    display: flex;

                    .facebook {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        cursor: pointer;
                        border-radius: 100%;
                        border: 1px solid #FFD11C;
                        margin-right: 13px;

                        .footer_facebook {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        svg {
                            margin-bottom: -3px;

                            path {
                                fill: white;
                            }
                        }

                        &:hover {
                            background: #FFD11C;

                            svg {
                                path {
                                    fill: black;
                                }
                            }
                        }
                    }

                    .linkedIn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        cursor: pointer;
                        border-radius: 100%;
                        border: 1px solid #FFD11C;
                        margin-right: 13px;

                        .footer_linkedIn {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        svg {

                            path {
                                fill: white;
                            }
                        }

                        &:hover {
                            background: #FFD11C;

                            svg {
                                path {
                                    fill: black;
                                }
                            }
                        }
                    }

                    .insta {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;
                        cursor: pointer;
                        border-radius: 100%;
                        border: 1px solid #FFD11C;

                        .footer_insta {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        svg {

                            path {
                                fill: white;
                            }
                        }

                        &:hover {
                            background: #FFD11C;

                            svg {
                                path {
                                    fill: black;
                                }
                            }
                        }
                    }
                }

                .mainPartRow4 {
                    margin-top: 13.5em;

                    p {
                        margin-top: 0;
                    }
                }
            }

            .col2 {
                width: 30%;

                .mobNav {

                    ul {
                        display: flex;
                        flex-direction: column;
                        padding: 0;
                        margin-top: 0;

                        li {
                            list-style: none;
                            padding: 0;
                            margin-bottom: 23px;
                        }

                        p {
                            margin-bottom: 23px;
                            margin-top: 0;
                        }

                        .grey {
                            opacity: .5;
                        }
                    }
                }
            }
        }

        hr {
            border: 2px solid #FFFFFF;
            opacity: 0.1;
            margin: 10px 0 30px 0;
            width: 99.5%;
        }

        .aboutCompanyText {
            width: 100%;
            opacity: 0.5;
        }
    }

    @media screen and (max-width: 360px) {
        margin-top: 60px;

        .wrapper {
            .mainPart {
                .col2 {
                    width: 33%;
                }
            }
        }
    }
}