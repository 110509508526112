@import "../../assets/styles/variables.module";

.post_page {
  padding: 180px 0;
  &__card_horizontal {
    &:not(:first-child) {
      margin-top: 10rem;
    }
  }
  &__grid {
    margin-top: 7.375rem;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 3.4375rem;
    grid-row-gap: 11.6875rem;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3.125rem;
    &_all {
      color: #FFF;
      background: $primaryBlack;
      border: 2px solid $primaryYellow;
      border-radius: 100px;
      display: inline-block;
      width: 13rem; // 208px;
      height: 3rem; // 48px;
      &:hover {
        opacity: .8;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .post_page {
    &__grid {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

@media screen and (max-width: 800px) {
  .post_page {
    padding: 2.4375rem 0; //103px;
    &__list {
      .vertical_post_card:not(:first-child) {
        margin-top: 8rem;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .post_page {
    &__grid {
      margin-top: 3.75rem;
      grid-template-columns: minmax(0, 1fr);
      grid-row-gap: 3.75rem;
    }
  }
}