@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;900&display=swap');

html {
  font-size: clamp(14px, 1.1111111111vw, 16px);

  @media screen and (min-width: 1440px) {
    font-size: 1.1111111111vw;
  }
}

* {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

main {
  min-height: 40vmax;
}

.container {
  max-width: 81.25rem; // 1300px
  min-width: 20rem; // 320px
  padding: 0 1rem; // 0 10px
  width: 100%;
  margin: 0 auto;
}

.hidden {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.nowrap {
  white-space: nowrap;
}

.post_page,
.post_detail_page {
  animation: FadeIn 1s ease-in;
  @keyframes FadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }
}

header {
  z-index: 1;
}