// Cookie-script styles
#cookiescript_injected {
    background-color: #FFD11C !important;
    padding: 40px 160px !important;
    z-index: 9999999999 !important;

    @media screen and (max-width: 1024px) {
        padding: 40px 80px !important;
    }

    @media screen and (max-width: 425px) {
        padding: 20px !important;
    }

    #cookiescript_leftpart {
        margin-bottom: 50px;

        #cookiescript_description {
            font-family: 'Poppins'!important;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;

            #cookiescript_readmore {
                text-decoration: underline;
            }
        }

        #cookiescript_manage_wrap {
            .cookiescript_gear {
                fill: #000000;
            }
        }
    }

    #cookiescript_toppart {
        flex-direction: column !important;
        position: relative;

        #cookiescript_leftpart {
            max-width: 100%;

            #cookiescript_manage_wrap {
                margin: 20px 0 !important;
            }
        }

        #cookiescript_rightpart {
            margin-left: auto;
            position: absolute;
            right: 0;
            top: 65%;
            min-width: 0 !important;

            @media screen and (max-width: 1024px) {
                top: 62%;
            }

            @media screen and (max-width: 425px) {
                top: 65%;
                width: min-content;
            }

            #cookiescript_buttons {
                width: auto !important;
                flex-direction: row !important;

                #cookiescript_save {
                    width: 120px;
                    height: 40px;
                    background: #000000;
                    border: none;
                    border-radius: 90px;
                    color: #ffffff;
                    cursor: pointer;
                    font-family: 'Poppins'!important;
                    font-style: normal;
                    font-weight: normal;
                    padding-top: 2px;
                }

                #cookiescript_accept {
                    width: 120px;
                    height: 40px;
                    background: #000000;
                    border: 1px solid #000000;
                    color: #ffffff;
                    border-radius: 90px;
                    cursor: pointer;
                    text-decoration: none;
                    font-family: 'GT-Walsheim-Pro' !important;
                    font-style: normal;
                    font-weight: normal;
                    padding-top: 2px;
                }

                #cookiescript_reject {
                    width: 120px;
                    height: 38px;
                    background: transparent;
                    border: 1px solid #000000;
                    color: #000000;
                    border-radius: 90px;
                    cursor: pointer;
                    text-decoration: none;
                    font-family: 'Poppins'!important;
                    font-style: normal;
                    font-weight: normal;
                    padding-top: 3px;
                }
            }
        }
    }

    #cookiescript_bottompart {

        #cookiescript_cookietablewrap {

            #cookiescript_maintabs {
                background-color: #000000 !important;
                border-bottom: 1px solid #313131;

                #cookiescript_declaration {
                    border-right: 1px solid #313131;
                }

                #cookiescript_aboutcookies {
                    border-left: 1px solid #313131;
                }

                .cookiescript_active {
                    background-color: #000000 !important;
                    color: #FFD11C !important;
                    font-weight: 700;
                }
            }

            #cookiescript_tabscontent {

                #cookiescript_declarationwrap {
                    background-color: #000000 !important;
                    color: #ffffff !important;

                    #cookiescript_categories {
                        border-bottom: none;

                        div {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 25px;
                            background: transparent;
                            border: 1px solid #FFD11C;
                            border-radius: 90px;
                            cursor: pointer;
                            text-decoration: none;
                            color: #ffffff !important;
                            font-family: 'Poppins'!important;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 16px;

                            &.cookiescript_active {
                                background: #FFD11C;
                                color: #000000 !important;
                            }
                        }
                    }

                    #cookiescript_reportwrap {
                        font-family: 'Poppins'!important;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 15px;

                        div {
                            color: #ffffff !important;
                            font-size: 13px !important;
                            line-height: 20px !important;
                        }

                        table {
                            thead {
                                tr {
                                    th {
                                        color: #ffffff !important;
                                        background: #000000;
                                        border-bottom: 1px solid #313131;
                                        font-size: 12px !important;
                                        line-height: 14px !important;
                                    }
                                }
                            }
                            tbody {
                                tr {
                                    td {
                                        color: #ffffff !important;
                                        border-bottom: 1px solid #313131;
                                        font-size: 12px !important;
                                        line-height: 14px !important;
                                    }
                                }
                            }
                        }
                    }
                }

                #cookiescript_aboutwrap {
                    background-color: #000000 !important;
                    color: #ffffff !important;
                }
            }
        }
    }

    span {
        color: #000000 !important;
        font-family: 'Poppins'!important;
        font-style: normal;
        font-weight: normal;
        font-size: 0.95em;
        line-height: 1.4em;

        @media screen and (max-width: 1024px) {
            font-size: 12px;
            line-height: 14px;
        }
    }

    a {
        color: #000000 !important;
        font-family: 'Poppins'!important;
        font-style: normal;
        font-weight: normal;
        font-size: 0.95em;
        line-height: 1.4em;

        @media screen and (max-width: 1024px) {
            font-size: 12px;
            line-height: 14px;
        }
    }

    #cookiescript_reportdate {
        color: #000000 !important;
        font-size: 5px;
    }

    #cookiescript_copyright {
        font-size: 7px;

        @media screen and (max-width: 1024px) {
            a {
                font-size: 7px !important;
            }
        }
    }
}